<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-card class="elevation-12 mx-auto" width="700">
              <v-toolbar dark color="primary">
                <v-btn @click="backToLogin()" icon class="hidden-xs-only">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>Recuperar Senha - CAAMA </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-row justify="center" >
                    <v-col  >
                      <FormField :fields="fields" />
                      <vue-recaptcha
                        @verify="verifiqueMethod"
                        @expired="expiredMethod"
                        class="mt-4 ml-3"
                        ref="recaptcha"
                        sitekey="6Lfm-oYcAAAAAO_3TdoqK2LeMN-cajie8GjKNGA9"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <ButtonMain
                  @click.native="submit()"
                  text="redefinir"
                  class="primary mr-5 mb-5"
                ></ButtonMain>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import FormField from "@/components/atoms/forms/FormField.vue";
import api from "@/services/api";
import ButtonMain from "@/components/atoms/buttons/ButtonMain.vue";

export default {
  data() {
    return {
      verifique: false,
      confirmPassword: "",
      confirmPasswordRules: [
        (value) =>
          value === this.form.senha || "As senhas precisam estar iguais.",
      ],
      form: {
        cpf: null,
        email: null,
        telefone: null,
        senha: null,
      },
      fields: {
        cpf: {
          field: "InputMaskField",
          label: "CPF",
          valueField: null,
          placeholder: "CPF",
          maskFormat: `###.###.###-##`,
          cols: 6,
          rules: [(value) => !!value || "Campo obrigatório"],
        },
        telefone: {
          field: "InputMaskField",
          label: "Telefone",
          valueField: null,
          placeholder: "Telefone",
          maskFormat: "(##) #####-####",
          cols: 6,
          rules: [(value) => !!value || "Campo obrigatório"],
        },

        email: {
          field: "InputField",
          label: "Email",
          valueField: null,
          placeholder: "Email",
          cols: 12,
          rules: [(value) => !!value || "Campo obrigatório"],
        },

        senha: {
          field: "InputField",
          label: "Senha",
          valueField: null,
          type: "password",
          placeholder: "Senha",
          cols: 6,
          rules: [
            (value) => !!value || "Campo obrigatório",
            (v) => v?.length >= 8 || "Minimo 8 caracteres",
          ],
        },
        confirmarSenha: {
          field: "InputField",
          label: "Confirmar senha",
          valueField: null,
          type: "password",
          placeholder: "Confirmar senha",
          cols: 6,
          rules: [
            (value) => !!value || "Campo obrigatório",
            (value) =>
              value === this.fields.senha.value ||
              "As senhas digitadas não coincidem",
            (v) => v?.length >= 8 || "Minimo 8 caracteres",
          ],
        },
      },
    };
  },
  components: {
    FormField,
    VueRecaptcha,
    ButtonMain,
  },
  methods: {
    expiredMethod() {
      this.verifique = false;
      this.$root.vtoast.show({
        status: "error",
        title: "Atenção!",
        text: "Tempo esgotado, recaptcha responda o recaptcha novamente para continuar.",
        time: 5000,
      });
    },
    verifiqueMethod() {
      this.verifique = true;
    },
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    backToLogin() {
      this.$emit("backLogin", false);
    },
    submit() {
      this.form = {
        cpf: this.fields.cpf.value,
        email: this.fields.email.value,
        telefone: this.fields.telefone.value,
        senha: this.fields.senha.value,
      };
      if (!this.verifique) {
        this.$root.vtoast.show({
          status: "error",
          title: "Atenção!",
          text: "responda o recaptcha para continuar.",
          time: 5000,
        });
      }
      if (this.verifique) {
        api
          .putRecuperarSenha(this.form)
          .then((res) => {
            this.form = {};
            this.$root.vtoast.show({
              status: "success",
              title: "Sucesso!",
              text: "senha redefinida com sucesso!.",
              time: 2000,
            });
            this.backToLogin();
          })
          .catch((data) => {
            this.$root.vtoast.show({
              status: "error",
              title: "Atenção!",
              text: data.mensagemUsuario
                ? data.mensagemUsuario
                : "dados Invalidos.",
              time: 5000,
            });
          });
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.form.senha === this.confirmPassword || "Password must match";
    },
  },
  created() {},
};
</script>

<style></style>
