<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-card class="elevation-12 mx-auto" width="700">
              <v-toolbar dark color="orange">
                <!-- <v-btn @click="backToLogin()" icon class="hidden-xs-only">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn> -->
                <v-toolbar-title>Primeiro Acesso - CAAMA</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                    <h3 class="mt-4 mb-3 ml-3">
                      Percebemos que este é o seu primeiro acesso. Por favor, altere sua senha.
                    </h3>
                  <v-row justify="center">
                    <v-col  >
                      <FormField :fields="fields" />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <ButtonMain
                  @click.native="submit()"
                  text="Confirmar"
                  class="primary mr-5 mb-5"
                ></ButtonMain>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import FormField from "@/components/atoms/forms/FormField.vue";
import api from "@/services/api";
import ButtonMain from "@/components/atoms/buttons/ButtonMain.vue";
import InputValidacaoSenha from '@/components/atoms/fields/InputValidacaoSenha.vue'
import { validadoresSenha } from '@/mixins/data/validadoresSenha.js'

export default {
  components: {
    FormField,
    ButtonMain,
    InputValidacaoSenha
  },

  data() {
    return {
      confirmPassword: "",
      form: {
        cpf: null,
        email: null,
        telefone: null,
        senha: null,
      },
      fields: {},
    };
  },

  mixins: [validadoresSenha],

  mounted() {
    this.fields = {
      senha: {
        field: 'InputValidacaoSenha',
        label: 'Senha',
        valueField: null,
        exibirValidacao: false,
        type: 'password',
        placeholder: 'Senha',
        cols: 12,
        rules: this.REGRAS_SENHA,
        validadores: this.CONDICOES_SENHA,
      },

      confirmarSenha: {
        field: 'InputField',
        label: 'Confirmar senha',
        valueField: null,
        type: 'password',
        placeholder: 'Confirmar senha',
        cols: 12,
        iconeSenha: 'mdi-eye-outline',
        rules: [
          (value) => !!value || 'Campo obrigatório',
          (value) => value === this.fields.senha.value || 'As senhas não são iguais',
        ]
      }
    }
  },

  methods: {
    ...mapActions({
      LogOut: 'auth/logOut'
    }),

    backToLogin() {
      this.LogOut()
      this.$emit("backLogin", false);
    },

    submit() {
      this.senha = this.fields.senha.value
      if (
        this.fields.senha.value !== this.fields.confirmarSenha.value ||
        this.fields.senha.value.length < 8 ||
        this.fields.senha.value.length > 12
      ) {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Atenção!',
          text: 'Senha inválida.',
          time: 15000
        })

        return;
      }

      const usuarioId = this.$store.getters['auth/user'].usuario
      api.putMudarSenha(usuarioId, this.fields.senha.value)
      .then(() => {
        this.form = {};
        this.$root.vtoast.show({
          status: "success",
          title: "Sucesso!",
          text: "Senha atualizada com sucesso!.",
          time: 2000,
        });
        this.backToLogin();
      })
      .catch((data) => {
        this.$root.vtoast.show({
          status: "error",
          title: "Atenção!",
          text: data.mensagemUsuario
            ? data.mensagemUsuario
            : "dados Invalidos.",
          time: 5000,
        });
      });
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.form.senha === this.confirmPassword || "Password must match";
    },
  },
  created() {},
};
</script>

<style></style>
