<template>
  <div>
    <div v-if="!senhaPadrao">
      <v-app v-if="!recuperarSenha">
        <v-main>
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex xs12 sm8 md4>
                <AlertMain v-if="loginInvalido" type="error" :message="msg" />
                <v-card class="elevation-12">
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Acesso - CAAMA </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        prepend-icon="mdi-account"
                        name="login"
                        label="Usuário"
                        type="text"
                        v-model="form.usuario"
                      ></v-text-field>
                      <v-text-field
                        id="password"
                        prepend-icon="mdi-lock"
                        :append-icon="iconSenha"
                        @click:append="trocarTipoSenha()"
                        name="password"
                        label="Senha"
                        :type="tipoSenha"
                        v-model="form.senha"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      @click="recuperarSenha = true"
                      text
                      color="blue darken-4 white--text"
                    >
                      RECUPERAR SENHA</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn @click="submit()" color="primary"
                      >Entrar
                      <v-icon>mdi-login</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-main>
      </v-app>

      <Register v-else @backLogin="recuperarSenha = $event" />
    </div>

    <div v-else>
      <PrimeiroAcesso @backLogin="senhaPadrao = $event; msg = ''"/>
    </div>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import AlertMain from '@/components/atoms/AlertMain.vue'
import Register from './Register.vue'
import PrimeiroAcesso from './PrimeiroAcesso.vue'

export default {
  components: {
    Register,
    AlertMain,
    PrimeiroAcesso
  },
  data() {
    return {
      iconSenha: 'mdi-eye-outline',
      tipoSenha: 'password',
      msg: 'Falha ao logar.',
      recuperarSenha: false,
      senhaPadrao: false,
      loginInvalido: false,
      form: {
        usuario: '',
        senha: ''
      }
    }
  },
  methods: {
    ...mapActions({
      Login: 'auth/Action_Login'
    }),
    trocarTipoSenha() {
      if (this.tipoSenha === 'password') {
        this.tipoSenha = 'text'
        this.iconSenha = 'mdi-eye-off-outline'
      } else {
        this.tipoSenha = 'password'
        this.iconSenha = 'mdi-eye-outline'
      }
    },
    submit() {
      this.$store.dispatch('loading', true)
      this.Login(this.form)
        .then(({ data }) => {
          this.$store.dispatch('loading', false)
          this.senhaPadrao = data.senhaPadrao

          if(!data.senhaPadrao) {
            this.$router.push('/')
          }
        })
        .catch(({ response }) => {
          if (response) {
            this.msg = response.data.mensagemUsuario
          }
          this.loginInvalido = true
          this.$store.dispatch('loading', false)
        })
    }
  }
}
</script>

<style></style>
